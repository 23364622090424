export const FirebaseConfig = {
	"projectId": "gogedit-apps",
	"appId": "1:705601497193:web:8a73bbc9f156e6c4e6daf8",
	"databaseURL": "https://gogedit-apps-default-rtdb.firebaseio.com",
	"storageBucket": "gogedit-apps.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyCbOVFnux3Q3nDh6KxkZ47qQn_MtCi8s04",
	"authDomain": "gogedit-apps.web.app",
	"messagingSenderId": "705601497193",
	"measurementId": "G-CT76Y38CKR"
};